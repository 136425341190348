import React, { useEffect, useState, useRef, Component } from "react";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Tooltip as ReactTooltip,
} from "@material-ui/core";
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowRight as ArrowRight,
  KeyboardArrowUp as ArrowUp,
} from "@material-ui/icons";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import { useDialog } from "react-mui-dialog";
import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";


let client;
const LIST = gql(`
  query MyQuery($username: String!) {
    devices(username:$username) {
      items {
        iotServiceID
        updatedAt
        username
        deviceType
        deviceModel
        deviceName
        location
        tags
        wifi_rssi
        relay1
        relay2
        state
        output_rate
        frequency
        voltage
        current
        power
        Temperature
        Humidity
        PM10
        PM25
        PM40
        PM100
        analogIn1
        temp_offset
        humi_offset
        temp_alert
        pm_alert
        iot_topic_keymap
        iot_topic_keytype
        iot_topic_read
        iot_topic_write
        iot_status
        calcparams
        tags
        children {
          iotServiceID
          updatedAt
          username
          deviceType
          deviceModel
          deviceName
          location
          tags
          wifi_rssi
          relay1
          relay2
          state
          output_rate
          frequency
          voltage
          current
          power
          Temperature
          Humidity
          PM10
          PM25
          PM40
          PM100
          analogIn1
          temp_offset
          humi_offset
          temp_alert
          pm_alert
          iot_topic_keymap
          iot_topic_keytype
          iot_topic_read
          iot_topic_write
          iot_status
          calcparams
          tags
        }
      }
    }
  }
`)


let renderHeaderCell;
let renderIndexCell;
let renderTagsCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;


export default function Devices({ client, maps, onselect, onupdate }) {
  var classes = useStyles();
  var theme = useTheme();
  const { openDialog } = useDialog();
  const [view, setView] = useState("table");
  const [device, setDevice] = useState({});
  const [devices, setDevices] = useState([]);
  const [version, setVersion] = useState();

  // sort
  const [orderby, setOrderby] = useState("");
  const [orderdir, setOrderdir] = useState("asc");

  const [parentchartdata, setParentChartData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  
  const [parentChartState, setParentChartState ] = useState("3時間分");
  const [chartState, setChartState] = useState("3時間分");

  const [ parentDeviceValue, setParentDeviceValue ] = useState("0");
  const [ deviceValue, setDeviceValue ] = useState("0");

  const [treeState, setTreeState] = useState(TreeState.create(devices)); // mockData
  const [treeStateData, setTreeStateData] = useState({}); // mockData
  const [key, setKey ] = useState(0);
  const [mainChartState, setMainChartState] = useState("monthly");
  const treeTableRef = useRef(null);
  
  let reorder = (items2, key2, dir2) => {
    let key = key2 ? key2 : orderby;
    let dir = dir2 ? dir2 : orderdir;
    let tags = {}; // hash = unique tags from all devices
    // convert from { various keys, children: [{...}, {...}]} to  { data: { various keys}, children: [ { data: { various keys }}, ...]}
    let items = JSON.parse(JSON.stringify(items2));
    console.log('reorder() items:', items);
    let flatlist = {};
    // convert from { various keys, children: [{...}, {...}]} to  { data: { various keys}, children: [ { data: { various keys }}, ...]}
    items.forEach((item) => {
      let children = item.children;
      if ( ! children) children = [];
      delete item.children;
      // do the same for children
      if ( ! flatlist[item.iotServiceID]) flatlist[item.iotServiceID] = item;
      children.forEach((child) => {
        var mychild = JSON.parse(JSON.stringify(child));
        if (flatlist[ mychild.iotServiceID]) mychild = flatlist[ mychild.iotServiceID];
        delete mychild.children;
        if ( ! mychild.parents) mychild.parents = [];
        mychild.parents.push(item.iotServiceID);
        flatlist[mychild.iotServiceID] = mychild;
      });
    });
    // 2nd run -- attech children to parents
    Object.keys(flatlist).map((id) => {
      let mychild = flatlist[id];
      if ( mychild.tags) mychild.tags.split(' ').map((tag) => { tags[tag] = 1; });
      if ( ! mychild.parents) return;
      mychild.parents.map((pid) => {
        if ( ! flatlist[ pid]) return;
        if ( ! flatlist[pid].children) flatlist[pid].children = [];
        flatlist[pid].children.push(mychild);
      });
    });
    flatlist = Object.values(flatlist); // these are unique objects with multiple parents (if any)
    let data = []; 
    flatlist.map((item) => { 
      item = JSON.parse(JSON.stringify(item));
      let children = item.children;
      delete item.children;
      data.push({ data: item, children: item.children }); 
    });
    console.log('reorder() data:', data);
    if ( ! key) key = 'iotServiceID';
    // order the items in accordance with orderby value (key)
    let h = {}; let spacer = ' ';
    data.forEach((item) => { 
      let k = item.data[ key] ? item.data[ key] + ' ' : '';
      if ( k) k += item.data.iotServiceID; 
      else { k += spacer; spacer += ' '; } // for empty value, just make sure that they are distinct
      h[ k.toLowerCase()] = item;
    });
    console.log( 'reorder() keys, sorted keys:', JSON.stringify(Object.keys(h)), JSON.stringify(Object.keys(h).sort()));
    let keys = Object.keys(h).sort();
    let data2 = [];
    keys.forEach((key) => { 
      if ( dir == 'asc') data2.push(h[key]);
      else data2.unshift(h[key]);
    });

    // group by tags
    var tagh = {}; // { tag: [ items], ...}
    data2.forEach((item) => {
      let tags = [item.data.iotServiceID]; // service id is the default tag for inividual items
      if ( item.data.tags) item.data.tags.split(' ').map((tag) => { tags.push(tag); });
      tags.forEach((tag) => {
        if ( ! tagh[tag]) tagh[tag] = [];
        tagh[tag].push(item);
      });
    });
    console.log( 'TAG keys', Object.keys(tagh));
    // remap the tagh
    for ( let tag in tagh) { 
      let items = tagh[ tag]; 
      tagh[ tag] = { tag: tag, childs: []};
      items.map((item) => { tagh[ tag].childs.push(item.data); });
    }

    // set the new tree state
    setTreeStateData(tagh);
    setTreeState(TreeState.create(data2));

    // check localStorage, and trigger on select if it is in the details view
    if ( localStorage.getItem('view') != 'details') return;
    let detailstag = localStorage.getItem('detailstag'); 
    if ( ! detailstag || ! tagh[ detailstag]) return;
    onselect( tagh[ detailstag]);
    // 
  }

  let reorder2 = (items2, key2, dir2) => {
    let key = key2 ? key2 : orderby;
    let dir = dir2 ? dir2 : orderdir;
    // convert from { various keys, children: [{...}, {...}]} to  { data: { various keys}, children: [ { data: { various keys }}, ...]}
    let data = []; let items = JSON.parse(JSON.stringify(items2));
    console.log('reorder() items:', items);
    // flatten the list before grouping
    items.forEach((item) => {
      let children = item.children;
      if ( ! children) children = [];
      delete item.children;
      // do the same for children
      let children2 = []
      data.push(item);
      children.forEach((child) => {
        delete child.children;
        data.push(child);
      });

    });
    console.log('reorder() data (before):', data);
    // order the items in accordance with orderby value (key)
    let h = {}; let spacer = ' '; if ( ! key) key = 'iotServiceID';
    data.forEach((item) => { 
      let k = item[ key] ? item[ key] + ' ' : '';
      if ( k) k += item.iotServiceID; 
      else { k += spacer; spacer += ' '; } // for empty value, just make sure that they are distinct
      h[ k.toLowerCase()] = item;
    });
    console.log( 'reorder() sorted/unsorted keys:', JSON.stringify(Object.keys(h)), JSON.stringify(Object.keys(h).sort()));
    let keys = Object.keys(h).sort();
    let data2 = [];
    keys.forEach((key) => { 
      if ( dir == 'asc') data2.push(h[key]);
      else data2.unshift(h[key]);
    });

    // group by tags
    var tagh = {}; // { tag: [ items], ...}
    data2.forEach((item) => {
      let tags = item.tags ? item.tags.split( ' ') : [ 'notag'];
      tags.forEach((tag) => {
        if ( ! tagh[tag]) tagh[tag] = [];
        tagh[tag].push(item);
      });
    });

    // now, create the final data2  -- a table with the mixed content as below
    // (1) for items with tag: tag is the parent iotServiceID is the tag itself
    // (2) for items without tag: no parents, just keep them as is (flat list)
    data2 = [];
    for ( let tag in tagh) if ( tag != 'notag') {
      var ids = []; // need this info to pass to the details page
      var childs = [];
      tagh[tag].forEach((item) => { ids.push(item.iotServiceID); childs.push(item); });
      let item = { data: { iotServiceID: tag, tag: tag, ids: ids, childs: childs}, children: []};
      tagh[tag].forEach((child) => { item.children.push({ data: child, children: [] }); });
      data2.push(item);
    }
    for ( let tag in tagh) if ( tag == 'notag') {
      tagh[tag].forEach((item) => { 
        let ids = [ item.iotServiceID];
        let childs = [ item];
        item.tag = tag;
        item.ids = ids;
        item.childs = childs;
        data2.push({ data: item, children: []});
      });
    }

    // set the new tree state
    setTreeState(TreeState.create(data2));
  }

  // DEVICES
  let getdevices = () => { client.query({ query: LIST, variables: { username: localStorage.getItem('username')}, fetchPolicy: 'network-only'}).then((result) => { 
    console.log("DEVICES", result.data.devices.items);
    setVersion(localStorage.getItem('version')); 
    for ( let k in result.data.devices.items) result.data.devices.items[k].timestamp = new Date().getTime();
    
    // if any, parse calcparams as  k1=v1 k2=v2 ...
    for ( let k in result.data.devices.items) if ( result.data.devices.items[k].calcparams) {
      let calcparams = result.data.devices.items[k].calcparams;
      // if calcparams is string (not parsed yet), then parse it
      let calcparams2 = {};
      if ( typeof calcparams == 'string') calcparams.split(' ').map((item) => { let kv = item.split('='); calcparams2[kv[0]] = kv[1]; });
      else calcparams2 = calcparams;
      result.data.devices.items[k].calcparams = calcparams2;
    }

    setDevices( result.data.devices.items); 
    reorder( result.data.devices.items);
  });}
  useEffect(() => { getdevices(); }, []);


  
  renderHeaderCell = (key: string, name: string, alignLeft: boolean = true) => {
    return () => {
      let items = [];
      if ( ! key || ! name) return items;
      items.push(<span className={alignLeft ? "align-left" : "align-right"}>{name}</span>);
      if ( key != orderby) items.push(<ArrowRight 
        onClick={() => { reorder(devices, key, 'asc'); setOrderby(key); setOrderdir('asc'); }}
      />);
      else if ( key == orderby && orderdir == 'desc') items.push(<ArrowDown 
        onClick={() => { reorder(devices, key, 'asc'); setOrderdir('asc'); }}
      />);
      else if ( key == orderby && orderdir == 'asc') items.push(<ArrowUp 
        onClick={() => { reorder(devices, key, 'desc'); setOrderdir('desc'); }}
      />);
      return items;
    }
  }

  let drawall = (v) => {
    // online processing of deleted items
    //let state = [];
    //treeStateData.map((item) => { state.push(item); });
    //setTreeState(TreeState.create(state));
    console.log('drawall() treeStateData', treeStateData);
    console.log('drawall() version vs localStorage.version', version, localStorage.getItem('version'));
    if ( '' + version != '' + localStorage.getItem('version')) {
      console.log('drawall() NEW VERSION!');
      setVersion( localStorage.getItem('version'));
      getdevices();
    }
    
    // infer keymap2 from maps.config.tableMap
    let keymap2 = [];
    if ( maps && maps.config && maps.config.tableMap) maps.config.tableMap.map((item) => { keymap2.push({ key: item.key, label: item.value}); });

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TreeTable className="demo-tree-table"
              height={360}
              headerHeight={32}
              ref={treeTableRef}
              value={treeState}
              onChange={(newVal) => { console.log("`onChange` call", newVal); setTreeState(newVal); }}>
              <TreeTable.Column renderCell={renderIndexCell} renderHeaderCell={renderHeaderCell( 'iotServiceID', maps.labels.iotserviceid)} basis="180px" grow={0}/>
              <TreeTable.Column renderCell={renderTagsCell} renderHeaderCell={renderHeaderCell('tags', maps.labels.tags)}/>
              {(() => {
                // walk through the array [{ key, label}, ...]
                return keymap2.map((h, i) => {
                  if ( ! i) return; // skip the iotservice id
                  return (
                    <TreeTable.Column 
                      renderCell={(row) => { 
                        let stuff = ( h.key == 'updatedAt') ? new Date(1000*parseInt( '' + row.data.updatedAt)).toLocaleString('ja-JP') : row.data[h.key];
                        if ( h.key != 'location') return (
                          <span className="employees-cell">
                            { stuff }
                          </span>
                        )
                        return (
                          <ReactTooltip 
                            //placement="left"
                            //style={{ "z-index":100000 }}
                            title={stuff}
                            aria-label="add"
                            placement="right"
                            PopperProps={{
                            disablePortal: true,
                            popperOptions: {
                              positionFixed: true,
                              modifiers: {
                                preventOverflow: {
                                enabled: true,
                                boundariesElement: "window" // where "window" is the boundary
                                }
                              }
                            }
                            }}
                          >
                            <span 
                            className="unfoldable-cell"
                            //style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                            >
                            { stuff }
                            </span>
                        </ReactTooltip>
                        )
                      }}
                      renderHeaderCell={renderHeaderCell( h.key, h.label, false)} 
                    />
                  )
                });
              })()}
            </TreeTable>
          </Grid>
        </Grid>
    </>
    );
  }

  renderViewButton = (row) => {
    return (
      <>
        <Link 
          class="link-button"
          style={{ 'margin-right': '5px'}}
          //to={{ pathname: "/app/dashboard", state:row }} 
          onClick={() => { 
            //console.log( 'selected DEVICE', row.data); 
            //console.log( 'selected PARENT DEVICE', row.data.parent);
            //setDevice(row.data); setView("details"); 
            onselect(row.data);
          }}
        >
          { maps.labels.edit }
        </Link>
        <Link 
          class="link-button"
          style={{ 'margin-left': '5px'}}
          //to={{ pathname: "/app/dashboard", state:row }} 
          onClick={() => {  }}
        >
          { maps.labels.delete }
        </Link>
      </>
    );
  }
  
  renderIndexCell = (row) => {
    return (<Link 
      class="link-button"
      style={{ 'margin-right': '5px'}}
      //to={{ pathname: "/app/dashboard", state:row }} 
      onClick={() => { 
        console.log( 'Devices (ONSELECT1) iotServiceID, list', row.data.iotServiceID, treeStateData[ row.data.iotServiceID]); 
        onselect(treeStateData[ row.data.iotServiceID]); 
      }}
    >
      { row.data.iotServiceID }
    </Link>);
  }
  
  renderTagsCell = (row) => {
    let items = [];
    if ( ! row.data.tags) return items;
    row.data.tags.split(' ').map((tag) => {
      items.push(<Link 
        class="link-button"
        style={{ 'margin-right': '5px'}}
        //to={{ pathname: "/app/dashboard", state:row }} 
        onClick={() => { onselect(treeStateData[tag]); }}
      >
        { tag }
      </Link>);
    });
    return items; 
  }

  // will loop through keys/values creating columns in the below table
  return drawall();

}




renderEditableCell = (row) => {
  return (
    <input type="text" value={row.data.contact}
      onChange={(event) => {
        // .updateData will notify the TreeTable instance to dispatch an onChange call with 
        // with a new value which includes the patched row data. The change will be visible 
        // if the new value is picked up and passed through TreeTable's value prop. 
        row.updateData({
          ...row.data,
          contact: event.target.value,
        });
      }}/>
  );
}

renderEmployeesCell = (row: Row<DemoDataItem>) => {
  return (
    <span className="employees-cell">{row.data.employees}</span>
  );
}

renderExpensesCell = (row: Row<DemoDataItem>) => {
  return (
    <span className="expenses-cell">{row.data.expenses}</span>
  );
}
