import React, { useEffect, useState, useRef, Component } from "react";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  Chip,
  Box,
  InputLabel,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";
import {
  ExposurePlus1 as Plus
} from "@material-ui/icons";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

import DevicesFlat from "./DevicesFlat";
import DeviceDetails from "./DeviceDetails";

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";

import { labels } from "../../components/App";

const CONFIG = gql(`
  query MyQuery($username: String) {
    getConfig(username: $username) {
        config
    }
  }
`)

const MANAGE = gql(`
  query MyQuery($action: String, $username: String, $iotServiceID: String, $parents: String, $deviceType: String, $deviceModel: String, $location: String, $deviceName: String, $tags: String, $calcparams: String) {
    deviceManage(action: $action, username: $username, iotServiceID: $iotServiceID, parents: $parents, deviceType: $deviceType, deviceModel: $deviceModel, location: $location, deviceName: $deviceName, tags: $tags, calcparams: $calcparams) {
      status
    }
  }
`);


export default function Manage() {
  var classes = useStyles();
  var theme = useTheme();
  const [view, setView] = useState("table");
  const [loading, setLoading] = useState('loading');
  const [version, setVersion] = useState(0); // used to trigger redraw on flatlist
  const [mode, setMode] = useState("create");
  const [device, setDevice] = useState({});
  const [badid, setBadID] = useState(false);
  const [badparams, setBadParams] = useState(false);
  const [devices, setDevices] = useState([]);
  const [devicesdata, setDevicesData] = useState([]); // 
  const [deleted, setDeleted] = useState([]); // [ iotServiceID, ...
  const [parents, setParents] = useState([]);

  // tags
  const [tagmode, setTagMode] = useState('select');
  const [tags, setTags] = useState([]);
  const [stags, setSTags] = useState({}); // selected tags
  const [tagood, setTagGood] = useState(false);
  const [tag, setTag] = useState('');

  // config, all/general, then specific for map4type mapping
  const [config, setConfig] = useState({}); // { }
  const [maps, setMaps] = useState({labels:labels, config: {}}); // { labels, keymap, keymap2, key2map, key2map2, controlkeymap, controlkeymap2, chartkeymap, showcontrolkeymap, showcontrolkeymap2 }
  const [map4types, setMap4Types] = useState({tableMap:{}}); // { deviceType: [ deviceModel, ... ] }
  

  const [parentchartdata, setParentChartData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  
  const [parentChartState, setParentChartState ] = useState("3時間分");
  const [chartState, setChartState] = useState("3時間分");

  const [parentDeviceValue, setParentDeviceValue ] = useState("0");
  const [deviceValue, setDeviceValue ] = useState("0");

  const [treeState, setTreeState] = useState(TreeState.create(devices)); // mockData
  const [key, setKey ] = useState(0);
  const [mainChartState, setMainChartState] = useState("monthly");
  const treeTableRef = useRef(null);
  
  const client = useApolloClient();

  
  // CONFIG
  useEffect(() => { client.query({ query: CONFIG, variables: { username: localStorage.getItem('username') } }).then((result) => {
    console.log( 'type-model map', result);
    localStorage.setItem('version', 1);
    if ( ! result || ! result.data || ! result.data.getConfig) return;
    let config = JSON.parse(result.data.getConfig.config);
    console.log('config', config);
    
    // parse all the various configs and make them part of the state

    // map4types -- create list of applicable modals for each device type
    let map4types = {};
    if ( config && config.deviceModelLabels && config.deviceModelMap) config.deviceModelMap.map((h) => { 
      let key = h.key;
      if ( ! config.deviceModelLabels[key]) return;
      let label = config.deviceModelLabels[key];
      map4types[label] = h.value.split(';');
    });
    setMap4Types(map4types);
    console.log('map4types', map4types);

    // set the entire config object
    setConfig(config);
    let mymaps = { labels, config}
    setMaps(mymaps);
  })}, []);
  

  let send = (mode2,device2) => {
    console.log( 'send mode/device', mode2,device2);
    let item = {} // build item first
    
    // use reverse map to get the EN version of device type
    let rmap = {}; // type: rtype
    for ( var key in maps.config.deviceModelLabels) rmap[maps.config.deviceModelLabels[key]] = key;
    let type = rmap[device2.deviceType]; // this on is in EN
    let model = device2.deviceModel;

    // the special case of equation-based parameters
    // check if parameters are set before sending data to API
    let calcparams = []; // build in 2 steps: (1) k=v parts, then delimit by space
    if ( device2.calcparams) for ( var k in device2.calcparams) calcparams.push(k + '=' + device2.calcparams[k]);
    if ( calcparams.length > 0) item.calcparams = calcparams.join(' '); 
    if ( maps.config && maps.config['controlMapCalcParams-' + type + '-' + model] && ! calcparams) return setBadParams(true); 
    

    let keys = "iotServiceID deviceType deviceModel location deviceName".split(" ");
    keys.map((k) => item[k] = device2[k]);
    item.action = mode2;
    item.username = localStorage.getItem('username');
    if ( parents.length > 0) item.parents = parents.join(' '); // convert to string
    if ( stags) item.tags = Object.keys(stags).join(' '); // convert to string
    else delete item.parents; // parents argument could be empty
    //if ( device.parent) item.parent = device.parent.iotServiceID;
    console.log( 'item', item);
    setLoading('loading');
    client.query({ 
      query: MANAGE,
      variables: item, 
      fetchPolicy: 'network-only'
    }).then((result) => {
      setLoading('done');
      setVersion( new Date().getTime()); 
      if ( mode2 == 'create') { setDevice({...device, iotServiceID: ''}); setParents([]); return; }
      if ( mode2 == 'delete') {
        var v = parseInt(''+localStorage.getItem('version'));
        console.log( 'DELETE current version', v);
        localStorage.setItem('version', v+1);
        setDeleted( [ ...deleted, device2.iotServiceID]);
        return; 
      }
      if ( mode2 == 'update') { setView('table'); localStorage.setItem('view', 'table'); }
    })

  }


  let renderDevicesFlat = () => { return (
    <DevicesFlat 
      client={client} 
        mydeleted={deleted}
        onselect={(d) => { 
          console.log( "renderDevicesFlat() onselect device", d);
          let ps = []; if ( d && d.parents) d.parents.map((p) => ps.push(p));
          setParents(ps);
          setDevice(d); setMode('update'); setLoading(''); setView('form'); 
          localStorage.setItem('view', 'form');  
        }} 
        onupdate={(ds,tags2) => {
          console.log('flatlist update (ds,tags)', ds, tags2); 
          setTags(tags2); // tags from all devices
          setDevices(ds); 
          setLoading('');
        }}
        ondelete={(d) => { 
          console.log('flatlist delete', d); 
          send('delete', d);
        }}
      maps={maps} 
    />
  );}

  let rendertypes = (map) => {
    let items = [];
    Object.keys(map).map((k) => items.push(<MenuItem key={k} value={k}>{k}</MenuItem>));
    return items;
  }
  let rendermodels = (map, type) => {
    let items = [];
    if ( ! type || ! map[ type]) return items;
    map[ type].map((k) => items.push(<MenuItem key={k} value={k}>{k}</MenuItem>));
    return items;
  }
  let renderparents = (devs, dev) => {
    let items = []; 
    console.log('rederparents() devs,dev', devs, dev);
    devs.map((d) => { 
      // self can not be parent
      if ( dev.iotServiceID == d.iotServiceID) return;
      // a child cannot be parent (remapping would be too compex)
      if ( d.parents) return;
      //console.log( 'renderparents() passed! d', d);
      // create MenuItem, set selected when iotServiceID matches that of the parent
      let prevselect = false; 
      for ( var i in parents) if ( parents[i] == d.iotServiceID) prevselect = true; // skip if already selected
      items.push(<MenuItem 
        key={d.iotServiceID} value={d.iotServiceID} 
        style={{ 'font-weight': prevselect ? 'bold' : 'normal' }}
        selected={prevselect}
      >
        {d.iotServiceID}
      </MenuItem>);
    })
    return items;
  }


  let rendertags = (devs, dev, tags2) => {
    let items = []; 
    console.log('rendertags() devs,dev', devs, dev);
    tags2.map((t) => { items.push(<MenuItem 
        key={t} value={t} 
        style={{ 'font-weight': stags[t] ? 'bold' : 'normal' }}
        selected={stags[t]}
      >
        {t}
      </MenuItem>);
    })
    return items;
  }
  const changetags = (event) => {
    console.log("changetags() tags", tags);
    const { target: { value } } = event;
    var stags2 = {};
    value.map((v) => stags2[v] = true);
    setSTags(stags2);
  };
  let renderTagsSelect = (device2, tags2, stags2, tag2, tagood2) => {
    let items = [];
    items.push(
    <>
    <InputLabel id="demo-multiple-chip-label">タグ（選択）</InputLabel>
    <Select 
      multiple
      variant="outlined"
      color="primary" label="タグ（選択）"
      style={{ width: "40%" }}
      // value is list of keys of stags2
      value={Object.keys(stags2)}
      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
      onChange={changetags}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={value} />
          ))}
        </Box>
      )}
    >
      { rendertags( devices, device, tags2) }
    </Select>
    <Button 
      style={{ marginLeft: "10pt" }}
      variant="contained"
      color="secondary"
      onClick={() => {  // add to tags (makes it possible to select)
        setTagMode('create');
      }}
    >
      { labels.create }
    </Button>
    </>)
    return items;
  }
  let renderTagsCreate = (device2, tags2, stags2, tag2, tagood2) => {
    if ( tagmode == 'select') return renderTagsSelect(device2, tags2, stags2, tag2, tagood2);
    let items = [];
    items.push(<>
    <TextField
      required color="primary" label="タグ（新規）"
      style={{ width: "50%" }}
      //value={ }
      onChange={(e) => {  // validate
        let good = true; 
        let v = e.target.value.trim();
        if ( v.length < 2) good = false;
        if ( v.length > 25) good = false;
        if ( v.split(' ').length > 1) good = false;
        // disallow ,:;"'#%&()=+*~<>^`{}[]|\/
        [ ',', ':', ';', '"', "'", '#', '%', '&', '(', ')', '=', '+', '*', '~', '<', '>', '^', '`', '{', '}', '[', ']', '|', '\\', '/' ].map((c) => { if ( v.indexOf(c) >= 0) good = false; });
        setTagGood(good);
        if ( good) setTag(e.target.value.trim());
        console.log('renderTagsCreate() tag,good', e.target.value.trim(), good);
      }}
    />
    <Button 
      style={{ marginLeft: "10pt" }}
      variant="contained"
      color="primary"
      disabled={ ! tagood2 || ! tag2 }
      onClick={() => {  // add to tags (makes it possible to select)
        setTags([ ...tags, tag]);
        setTagMode('select');
      }}
    >
      { labels.create }
    </Button>
    <Button 
      style={{ marginLeft: "10pt" }}
      variant="contained"
      color="secondary"
      onClick={() => { setTagMode('select'); }}
    >
      { labels.cancel }
    </Button>
    </>)
    return items;
  }


  let rendercalc = (device2) => { // rtype is in JP, convert it to key
    let rtype = device2 ? device2.deviceType : '';
    console.log('rendercalc() entered with rtype', rtype);
    let rmap = {}; // rtype: type
    let items = [];
    if ( ! rtype) return items;
    if ( ! maps || ! maps.config || ! maps.config.deviceModelLabels) return items;
    for ( var key in maps.config.deviceModelLabels) rmap[maps.config.deviceModelLabels[key]] = key;
    let type = rmap[rtype]; // this on is in EN
    let model = device2 ? device2.deviceModel : '';
    console.log( 'rendercalc() rtype,type,config', rtype, type, maps.config);
    if ( ! maps || ! maps.config || ! maps.config['controlMapCalcParams-' + type + '-' + model]) return items;
    items.push(<Typography size="md">{maps.labels.equation_params}</Typography>)
    items.push(<div style={{ height: "10pt", clear:"both" }}></div>)
    maps.config['controlMapCalcParams-' + type + '-' + model].map((h) => {
      let value = device2 && device2.calcparams && device2.calcparams[h.key] ? device2.calcparams[h.key] : '';
      items.push(<TextField 
        required 
        color={ badparams ? "secondary" : "primary"}
        error={ badparams }
        helperText={badparams ? labels.manage.bad_calc_params : ''}
        label={ h.value }
        style={{ width: "30%" }}
        value={ value }
        onChange={(e) => { 
          let v = e.target.value.trim(); 
          setBadParams(false);
          setDevice({ ...device, calcparams: { ...device.calcparams, [h.key]: v } })
        }}
      />)
      items.push(<div style={{ height: "10pt", clear:"both" }}></div>)
      
    });
    return items;
  }
  const changeparents = (event) => {
    console.log("changeparents() parents", parents);
    const {
      target: { value },
    } = event;
    setParents(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };


  if ( view === "form") {
      let items = [];
      // form: (1) iotServiceID, (1b) parent (select), (2) deviceType (select), (3) deviceModel, (4) location, (5) deviceName
      items.push(
        <>
        <Link 
          style={{ color: theme.palette.text.hint + "80", textDecoration: "none", fontSize: 18, cursor: "pointer"  }}
          onClick={() => { setView("table"); setLoading(''); localStorage.setItem('view', 'table'); }}
        >
            { labels.backtotable }
        </Link>
        <PageTitle 
          title={ labels[mode] + ( device.iotServiceID ? '（' + device.iotServiceID + '）' : '')} 
          //button={ labels[mode] } 
          //onClick={() => { 
          //  console.log( 'flatlist', devices);
          //  console.log( 'device', device);
          //}} 
        />

        <div style={{ height: "5pt", clear:"both" }}></div>
        <span style={{ color:'red'}}>
          { loading == 'loading' ? '送信中...' : ( loading == 'done' ? '送信済、次のデバイスが登録ができます。': '') }
        </span>
        <div style={{ height: "5pt", clear:"both" }}></div>

        <TextField 
          required 
          color={ badid ? "secondary" : "primary"}
          error={ badid }
          helperText={badid ? labels.manage.bad_iot_service_id : ''}
          label="IoTサービスID"
          style={{ width: "50%" }}
          value={ device ? device.iotServiceID : ''}
          disabled={ mode == 'update' }
          onChange={(e) => { 
            let v = e.target.value; 
            let bad = false;
            if ( v.length < 3) bad = true;
            if ( v.length > 25) bad = true;
            if ( v.split(' ').length > 1) bad = true;
            devices.map((d) => { 
              console.log( 'compare v[' + v + '] d.iotServiceID[' + d.iotServiceID + ']');
              if ( d.iotServiceID == v) bad = true; 
            });
            setBadID(bad);
            setDevice({ ...device, iotServiceID: v })
          }}
        />
        
        <div style={{ height: "30pt", clear:"both" }}></div>

        <TextField 
          select
          required
          variant="outlined"
          color="primary" label="デバイス分類"
          style={{ width: "50%" }}
          value={ device ? device.deviceType : ''}
          onChange={(e) => { setDevice({ ...device, deviceType: e.target.value }) }}
        >
          { rendertypes(map4types) }
        </TextField>
        
        <div style={{ height: "30pt", clear:"both" }}></div>
        
        <TextField 
          select
          required
          variant="outlined"
          color="primary" label="デバイス型番"
          style={{ width: "50%" }}
          value={ device ? device.deviceModel : ''}
          onChange={(e) => { setDevice({ ...device, deviceModel: e.target.value }) }}
        >
          { rendermodels(map4types, device.deviceType) }
        </TextField>


        <div style={{ display: "none", height: "30pt", clear:"both" }}></div>
        <InputLabel style={{ display: "none"}} id="demo-multiple-chip-label">親デバイス</InputLabel>
        <Select 
          multiple
          variant="outlined"
          color="primary" label="親デバイス"
          style={{ width: "50%", display:"none" }}
          value={parents} // device && device.parent ? device.parent.iotServiceID : ''
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          onChange={changeparents}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          { renderparents( devices, device) }
        </Select>


        <div style={{ height: "30pt", clear:"both" }}></div>

        <TextField
          required color="primary" label="設置場所"
          style={{ width: "50%" }}
          value={ device ? device.location : ''}
          onChange={(e) => { setDevice({ ...device, location: e.target.value }) }}
        />

        <div style={{ height: "30pt", clear:"both" }}></div>

        <TextField
          required color="primary" label="デバイス名前"
          style={{ width: "50%" }}
          value={ device ? device.deviceName : ''}
          onChange={(e) => { setDevice({ ...device, deviceName: e.target.value }) }}
        />

        <div style={{ height: "30pt", clear:"both" }}></div>
        
        { renderTagsCreate(device, tags, stags, tag, tagood) }

        <div style={{ height: "30pt", clear:"both" }}></div>

        { rendercalc(device) }

        <div style={{ height: "30pt", clear:"both" }}></div>

        <Button
          variant="contained"
          color="primary"
          onClick={() => { send(mode, device); }} 
          onMouseOver={() => { // check calcparams
            console.log( 'checking calcparams');
            if ( ! device.deviceType) return setBadParams(true);
            let rmap = {}; // rtype: type
            for ( var key in maps.config.deviceModelLabels) rmap[maps.config.deviceModelLabels[key]] = key;
            let type = rmap[device.deviceType]; // this on is in EN
            let model = device.deviceModel;
            // if no calcparams are configured, no need to check
            if ( ! maps.config['controlMapCalcParams-' + type + '-' + model]) return setBadParams(false);
            // calc params are set! check if all have values
            let bad = false;
            maps.config['controlMapCalcParams-' + type + '-' + model].map((h) => { if ( ! device.calcparams || ! device.calcparams[h.key] || device.calcparams[h.key].trim() == '') bad = true; });
            setBadParams(bad);
          }}
          disabled={ badid || badparams || ! device.iotServiceID || ! device.deviceType || ! device.deviceModel || ! device.location || ! device.deviceName }
        >
          { labels[mode] }
        </Button>
        
        </>
      );
      return items;
  }
  if ( view === "table") {
    console.log('VIEW: table');
    return (
      <>
        <PageTitle title="デバイス一覧（フラット型）" button={labels.create} onClick={() => { 
          setDevice({}); 
          setView('form');
          setMode('create'); 
          localStorage.setItem('view', 'form'); 
        }} />
        { renderDevicesFlat() }
      </>
    )
  }

}
