import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";


import { useState, useEffect } from 'react';
// import { useUserState } from "../context/UserContext";

import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Rehydrated } from "aws-appsync-react";
import Amplify, { Auth } from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";

//import gql from "graphql-tag";
// import { ApolloProvider, Query } from "react-apollo";
import { ApolloProvider, Query } from '@apollo/client';
import { ApolloClient, InMemoryCache, gql } from '@apollo/client';


import awsmobile from "../aws-exports";

export const labels = {
  details: "詳細",
  edit: "編集",
  create: "新規作成",
  update: "更新",
  delete: "削除",
  cancel: "キャンセル",
  iotserviceid: "IoTサービスID",
  backtotable: "← デバイス一覧に戻る",
  parentORchild: "親デバイス",
  tags: "タグ",
  equation_params: "計算式パラメター",
  alerts: {
    cancel: 'キャンセル',
    confirm: '実行',
    ok: '閉じる',
    control_locked: '制御送信中（送信完了まで制御操作はできません。）',
    control_pending: '制御操作に失敗しました。デバイスの通信状態を確認してください。',
    control_title_before: '送信前確認',
    control_message_before: '制御指示を出しますがよいですか？',
    control_title_after: '送信後確認',
    control_message_after: '制御指示が送信されました。1分経過しても反映されない場合はデバイスがオフラインになっている可能性がありますのでご確認ください。',
    delete_title: "削除確認",
    delete_warning: "このデバイスを削除しますがよろしいでしょうか？",
  },
  manage: {
    bad_iot_service_id: "iotServiceIDが無効です（3文字以上、25文字以下、スペース無し、重複禁止）",
    bad_calc_params: "計算式パラメターに空欄が禁じられている。",
  },
  saveAsCSV: "CSVで保存",
}

let client;
let config = awsmobile;
// var { isAuthenticated } = useUserState();
const isAuthenticated = true;

/* basic exable of a graphql query
const LIST = gql(`
  query MyQuery {
    devices(count: 10) {
      items {
        iotServiceID
        createdAt
        name
        tag
        macAddress
        connectionState
        rssi
        fwVersion
      }
      nextToken
    }
  }
`)
*/


function Wrapper({ authData }) {
  
  console.log("Your user authData", authData);
  console.log("username", authData.username);
  localStorage.setItem("username", authData.username);
  const [devices, setDevices] = useState(null);


  /**
  async function getDevices() { client.query({ 
      query: LIST
  }).then((result) => { console.log(result); setDevices(result.data.devices.items); }); }
  useEffect(() => { getDevices(); }, []);
  */

  /* removed because switched  react-apollo → @apollo/client
  <Rehydrated></Rehydrated>
  */

  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/app/tables" />} />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/tables" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          <PublicRoute path="/login" component={Login} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </ApolloProvider>
  );
}

Amplify.configure(config);
client = new AWSAppSyncClient({
  url: config.aws_appsync_graphqlEndpoint,
  region: config.aws_appsync_region,
  disableOffline: true,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  }
});
export default withAuthenticator(Wrapper);


function PrivateRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        ( isAuthenticated || 1) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        ( isAuthenticated || 1) ? (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}

