import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  Settings as SettingsIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";


// intiailization, just put the labels there
let labels = {
  details: "詳細",
  edit: "編集",
  create: "新規作成",
  update: "更新",
  delete: "削除",
  iotserviceid: "IoTサービスID",
  backtotable: "← デバイス一覧に戻る",
  parentORchild: "親デバイス",
  alerts: {
    cancel: 'キャンセル',
    confirm: '実行',
    ok: '閉じる',
    control_locked: '制御送信中（送信完了まで制御操作はできません。）',
    control_pending: '制御操作に失敗しました。デバイスの通信状態を確認してください。',
    control_title_before: '送信前確認',
    control_message_before: '制御指示を出しますがよいですか？',
    control_title_after: '送信後確認',
    control_message_after: '制御指示が送信されました。1分経過しても反映されない場合はデバイスがオフラインになっている可能性がありますのでご確認ください。',
    delete_title: "削除確認",
    delete_warning: "このデバイスを削除しますがよろしいでしょうか？",
  },
  manage: {
    bad_iot_service_id: "iotServiceIDが無効です（3文字以上、25文字以下、スペース無し、重複禁止）",
  }
}

const structure = [
  { id: 0, label: "デバイス一覧", link: "/app/tables", icon: <HomeIcon /> },
  { id: 1, label: "デバイス管理", link: "/app/manage", icon: <SettingsIcon /> },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });


  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
