import React, { useEffect, useState, useRef, Component } from "react";
import * as Icons from "@material-ui/icons";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";

import DeviceDetailsChart from "./DeviceDetailsChart";
import DeviceDetailsChartCalc from "./DeviceDetailsChartCalc";
import DeviceControlTuple from "./DeviceControlTuple";
import DeviceControl from "./DeviceControl";


let renderHeaderCell;
let renderIndexCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;


const DEVICE = gql(`
  query MyQuery($filter: String) {
    devices(filter:$filter) {
      items {
        iotServiceID
        updatedAt
        username
        deviceType
        deviceModel
        deviceName
        location
        wifi_rssi
        relay1
        relay2
        state
        output_rate
        frequency
        voltage
        current
        power
        Temperature
        Humidity
        PM10
        PM25
        PM40
        PM100
        analogIn1
        temp_offset
        humi_offset
        temp_alert
        pm_alert
        iot_topic_keymap
        iot_topic_keytype
        iot_topic_read
        iot_topic_write
        iot_status
        calcparams
        tags
      }
    }
  }
`)

export default function DeviceDetails({ client, device, maps, view }) {
  var classes = useStyles();
  var theme = useTheme();
  const [mytype, setMyType ] = useState('');
  const [mydevice, setMyDevice ] = useState(device);
  const [deviceValue, setDeviceValue ] = useState("0");
  const [locked, setLocked ] = useState(false);
  const [loading, setLoading ] = useState(false);
  const [key, setKey ] = useState(0);
  const treeTableRef = useRef(null);
  

  // get device type (key) as reverse map of map.config.deviceModelLabels
  let rtypemap = {};
  if ( maps.config && maps.config.deviceModelLabels) for ( var k in maps.config.deviceModelLabels) rtypemap[maps.config.deviceModelLabels[k]] = k;
  let type = ( rtypemap && device && rtypemap[device.deviceType]) ? rtypemap[device.deviceType] : '';
  console.log('MYTYPE type/deviceModel,rtypemap', type, device.deviceType, rtypemap);
  if ( ! type) return (<div>...</div>);
  if ( ! mytype) setMyType(type);
  
  //if ( 1) return (<div>.....</div>);
  
  // at a very early stage, if controlMapCalcParams is defined, add those keys to (1) details and (2) charts
  let rtype = type; // this is EN type
  let model = device.deviceModel;
  let calcmap = [];
  let calcmap2 = {};
  let calcparams = {}; // the device has calcparams with the values for the same set of keys
  let calceqs = {}; // { key: { eq, unit},...}
  let K = 'controlMapCalcKeys-' + rtype + '-' + model;  // all keys have to have model at the end
  if ( maps && maps.config && maps.config[K]) maps.config[K].map((item) => { calcmap.push({ key: item.key, label: item.value}); });
  if ( maps && maps.config && maps.config[K]) maps.config[K].map((item) => { calcmap2[item.key] = item.value; });
  K = 'controlMapCalcParams-' + rtype + '-' + model;  // all keys have to have model at the end
  if ( maps && maps.config && maps.config[K]) maps.config[K].map((item) => { calcparams[item.key] = item.value; });
  K = 'controlMapCalcEq-' + rtype + '-' + model;  // all keys have to have model at the end
  calcmap.map((item) => { calceqs[item.key] = maps.config[K + '-' + item.key]; });
  console.log('DeviceDetails (before return) rtype,calcmap,calcmap2,calcparams,calceqs,config', rtype, calcmap, calcmap2, calcparams, calceqs, maps.config);

  let keymap2 = [];
  if ( maps && maps.config && maps.config.detailsMap) maps.config.detailsMap.map((item) => { keymap2.push({ key: item.key, label: item.value}); });
  console.log('DeviceDetails (before return) keymap2', keymap2);
  
  //if ( 1) return (<div>.....</div>);
  
  let chartkeymap = [];
  let chartkeymap2 = {};
  K = 'chartMap-' + type + '-' + model;  // all keys have to have model at the end
  if ( maps && maps.config && maps.config[K]) chartkeymap = maps.config[K];
  if ( maps && maps.config && maps.config[K]) maps.config[K].forEach((v) => { chartkeymap2[v.key] = v.value; });
  if ( ! chartkeymap) return (<div>.....</div>);
  console.log('DeviceDetails (before return) chartkeymap,chartkeymap2', chartkeymap, chartkeymap2);
  
  let controlkeymap = [];
  let controlkeymap2 = {};
  let controltypemap2 = {}; // use as is
  K = 'controlMap-' + type + '-' + model;  // all keys have to have model at the end
  if ( maps && maps.config && maps.config[K]) controlkeymap = maps.config[K];
  if ( maps && maps.config && maps.config[K]) maps.config[K].forEach((v) => { controlkeymap2[v.key] = v.value; });
  K = 'controlWriteType-' + type + '-' + model;  // all keys have to have model at the end
  if ( maps && maps.config && maps.config[K]) controltypemap2 = maps.config[K];
  console.log('DeviceDetails (before return) controlkeymap,controlkeymap2,controltypemap2', controlkeymap, controlkeymap2, controltypemap2);
  
  let chartkeys = [];
  let chartnames = [];
  chartkeymap.forEach((v) => { chartkeys.push(v.key); chartnames.push(v.value); });
  console.log('DeviceDetails (before return) chartkeys,chartnames', chartkeys, chartnames);
  
  let controlkeys = [];
  let controlnames = [];
  controlkeymap.forEach((v) => { controlkeys.push(v.key); controlnames.push(v.value); });
  console.log('DeviceDetails (before return) controlkeys,controlnames', controlkeys, controlnames);
  

  // reload device info every 30 seconds, but keep the reload function itself separate so that others can use it
  let reload_device = () => { setLoading( true); client.query({ query: DEVICE, variables: { filter: device.iotServiceID}, fetchPolicy: 'network-only'}).then((result) => { 
    setLoading( false);
    console.log("DEVICE (reload)", result.data.devices.items);
    let device2 = result.data.devices.items[ 0];
    device2.timestamp = new Date().getTime();
    device2.calcparams = mydevice.calcparams; // do not allow change in calcparams on reload
    setMyDevice(device2);
    setLocked(false);
  });}
  let reload_device_30s = () => { if ( localStorage.getItem('view') != 'details') return; reload_device(); setTimeout(reload_device_30s, 30000); }
  //useEffect(() => { reload_device_30s(); }, []);

  let drawDetails = (device) => {
    // loop through all the keys in the device object and show then as H5 topography
    let items = [];
    // walk through all detail keys
    keymap2.map((h) => {
      let key = h.key;
      let label = h.label;
      if ( ! device[key]) return;
      let value = device[key];
      if ( key === "updatedAt" && value) value = new Date(parseInt( '' + value) * 1000).toLocaleString('ja-JP');
      items.push(
        <Grid item xs={4}>
          <Typography color="text" colorBrightness="secondary" noWrap>{label}</Typography>
          <Typography size="md">{value}</Typography>
        </Grid>
      );
    });
    return items; 
  }

  let drawControls = (device) => {
    let items = [];
    // walk through all control keys and show only those that have values in the db
    controlkeymap.map((h) => {
      let key = h.key;
      let label = h.value;
      let type = controltypemap2[key];
      // if key is absent in the object, skip it
      let value = device[key] ? new String( device[key]) : '';
      console.log( 'key/label/type/value', key, label, type, value);
      if ( ! value || value == 'null') return;
      value = ( type == 'TF' || type == 'TFN') ? ( parseInt('' + value) ? '運転中': '停止中') : value;
      items.push(
        <Grid item xs={4}>
          <Typography color="text" colorBrightness="secondary" noWrap>{label}</Typography>
          <Typography size="md">{ value }</Typography>
        </Grid>
      );
    });
    return items; 
  }

  let drawCalcs = (device) => {
    let items = [];
    // walk through all control keys and show only those that have values in the db
    calcmap.map((h) => {
      let key = h.key;
      let label = h.label;
      let eq = calceqs[key] ? calceqs[key].eq : ''; if ( ! eq) return; 
      let unit = calceqs[key] ? calceqs[key].unit : ''; if ( ! unit) return;
      
      // create a function using a string, add equation to it and execute it
      let f = '';
      for ( let k in controlkeymap2) f += 'let ' + k + ' = ' + device[k] + ";\n";
      for ( let k in calcparams) f += 'let ' + k + ' = ' + ( device.calcparams && device.calcparams[k] ? '' + parseFloat(device.calcparams[k]) : '0') + ";\n";
      f += 'let ' + key + ' = ' + eq + ";\n";
      f += 'return ' + key + ";\n";
      console.log('drawCalcs f', f);
      let value = new Function( f)();
      console.log( 'drawCalcs() key/label/type/value', key, label, type, value);
      if ( ! value || value == 'null') return;
      items.push(
        <Grid item xs={4}>
          <Typography color="text" colorBrightness="secondary" noWrap>{label}</Typography>
          <Typography size="md">{ value + ' ' + ( device.calcparams && device.calcparams[unit] ? device.calcparams[unit] : '') }</Typography>
        </Grid>
      );
    });
    return items; 
  }

  let drawCharts = (device) => {
    // loop through all the actual control keys
    let items = [];
    for (var i = 0; i < chartkeys.length; i++) {
      items.push(
        <DeviceDetailsChart client={client} device={device} maps={maps} chartkey={chartkeys[i]} chartname={chartnames[i]} />
      );
    }
    return items;
  }

  let drawChartsCalc = (device) => { // uses special Chart component which calculates each value using an equation
    // loop through all the actual control keys
    let items = [];
    calcmap.map((h) => {
      let key = h.key;
      let label = h.label;
      let eq = calceqs[key] ? calceqs[key].eq : ''; if ( ! eq) return; 
      let unit = calceqs[key] ? calceqs[key].unit : ''; if ( ! unit) return;
      // identify which control key is used in the equation -- the chart has to get the raw data for that key
      var chartkey = '';
      for ( let k in controlkeymap2) if ( eq.indexOf( k) >= 0) chartkey = k;
      if ( ! chartkey) return items;
      let calc = { key: key, label: label, eq: eq, unit: unit, calcmap, calcmap2, calcparams, calceqs};
      items.push(
        <DeviceDetailsChartCalc client={client} device={device} calc={calc} maps={maps} chartkey={chartkey} chartname={label} />
      );
    });
    return items;  
}


  
  //if ( 1) return (<div>.....</div>);
  return (
    <>
        {(() => { if ( device['iot_status'] == 'locked2' || locked === 1) return (
          <Typography variant="h3" color="secondary" className={classes.text}>
              { maps.labels.alerts.control_locked }
          </Typography>) }
        )()}
        {(() => { if ( device['iot_status'] == 'pending2') return (
          <Typography variant="h3" color="secondary" className={classes.text}>
              { maps.labels.alerts.control_pending }
          </Typography>) }
        )()}
        <div style={{ height:"50px", clear: "both" }}></div>
        <div className={classes.mainChartHeaderLabels} style={{ "text-align": "right" }}>
              更新 
              <Icons.Refresh 
                style={{ "margin": "10px", "cursor": "pointer" }}
                onClick={(e) => { reload_device(); }}
              />
              <span style={{ color:'red', 'font-size': 'smaller'}}> { loading ? ' ロード中...' : ' '} </span>
              <span style={{ 'font-size': 'smaller'}}> 自動更新はされません。 </span>
        </div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="left"
          margin={{ top: 50, bottom: 50, left: 5, right: 5 }}
          rowSpacing={50}
        >
          {(() => { return drawDetails(mydevice); })()}
          {(() => { return drawControls(mydevice); })()}
          {(() => { return drawCalcs(mydevice); })()}
        </Grid>
        <div style={{ height:"50px", clear: "both" }}></div>
        <DeviceControl 
          client={client} 
          device={mydevice}
          maps={maps} 
          onchange={() => { return mydevice; }}
        />
        <div style={{ height:"50px", clear: "both" }}></div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="left"
          margin={{ top: 50, bottom: 50, left: 5, right: 5 }}
          rowSpacing={50}
        >
          {(() => { return drawCharts(mydevice); })()}
          {(() => { return drawChartsCalc(mydevice); })()}
        </Grid>
        
    </>
  )

}
