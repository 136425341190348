import React, { useEffect, useState, useRef, Component } from "react";
import * as Icons from "@material-ui/icons";
import {Link} from 'react-router-dom';
import FileSaver from 'file-saver';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";


// parameters: iotServiceID, from, to
const TUPLES = gql(`
  query MyQuery( $iotServiceID: String!, $key: String, $now: String, $span: String) {
    tuples(iotServiceID: $iotServiceID, key: $key, now: $now, span: $span) {
      items {
        createdAt
        value
      }
    }
  }
`)



let renderHeaderCell;
let renderIndexCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;


export default function DeviceDetailsChart({ client, device, maps, chartkey, chartname }) {
  var classes = useStyles();
  var theme = useTheme();
  const [view, setView] = useState("table");
  const [loading, setLoading] = useState(false);
  const [chartdata, setChartData] = useState([]);
  const [chartState, setChartState] = useState("3時間分");
  const [deviceValue, setDeviceValue ] = useState("0");
  const [key, setKey ] = useState(0);
  const [mainChartState, setMainChartState] = useState("monthly");
  const treeTableRef = useRef(null);
  
  const [BrushComponent, setBrushComponent] = useState(
    <Brush
      className="TimeLineChart-brush"
      dataKey="timestamp"
      stroke="#8884d8"
      ariaLabel="Brush"
    />,
  );

  useEffect(() => {
    const handleResize = () => {
      console.log('Window size changed');
      setBrushComponent(
        chartdata.length > 0 ?
        <Brush
          className="TimeLineChart-brush"
          dataKey="timestamp"
          stroke="#8884d8"
          ariaLabel="Brush"
        /> : <></>
      )
      // Additional logic to handle window resize can be added here
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [chartdata]);

  // TUPLES
  let getTuples = (mydevice, span) => {
    if ( ! mydevice) return;
    if ( ! span) span = chartState;
    let vars = { iotServiceID: mydevice.iotServiceID, key: chartkey, now: "" + (new Date()).getTime(), span: span };
    setLoading(true);
    client.query({ query: TUPLES, variables: vars}).then((result) => { 
      console.log("TUPLES (" + mydevice.iotServiceID + ")", result.data.tuples.items);
      // build new chart
      let data = [];
      result.data.tuples.items.forEach((item) => {
          // convert unixtime to datetime
          let tuple = { "timestamp": new Date(1000*parseInt( '' + item.createdAt)).toLocaleString('ja-JP')}
          tuple[chartname] = item.value;
          data.push(tuple);
      });
      //console.log( 'chartdata', data);
      setChartData(data);
      setLoading(false);
    });
  }
  useEffect(() => { getTuples(device); }, []);


  return (
    <>
              <div className={classes.mainChartHeaderLabels} style={{ "text-align": "right" }}>
                <Select
                  value={chartState}
                  onChange={(e) => { setChartState(e.target.value); getTuples(device, e.target.value); }}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect,
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value="3時間分">3時間分</MenuItem>
                  <MenuItem value="1日分">1日分</MenuItem>
                  <MenuItem value="3日分">3日分</MenuItem>
                  <MenuItem value="1週間分">1週間分</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ "margin": "10px" }}
                  onClick={() => { 
                    var bom = false;
                    //var charset = e.target.value; // user selects which charset to output data in
                    var charset = 'UTF-8bom';
                    if ( charset == maps.labels.saveAsCSV) return;
                    if ( charset == "UTF-8bom") { charset = "UTF-8"; bom = true; }
                    // make blob from string and saveAs as CSV file
                    let csv = "timestamp," + chartname + "\n";
                    chartdata.forEach((row) => {
                      csv += row.timestamp + "," + row[chartname] + "\n";
                    });
                    // create block using charset as encoding
                    let blob = new Blob( bom ? ["\ufeff", csv] : [csv], { type: 'text/csv;charset=' + charset });
                    FileSaver.saveAs(blob, 'chartdata.csv');
                  }}
                >
                  { maps.labels.saveAsCSV }
                </Button>
                <Icons.Refresh 
                  style={{ "margin": "10px", "cursor": "pointer" }}
                  onClick={(e) => { getTuples(device, chartState); }}
                />
                <span style={{ color:'red', 'font-size': 'smaller'}}> { loading ? ' ロード中...' : ''} </span>
              </div>
              <ResponsiveContainer width={"100%"} height={300} min-width={300}>
                <LineChart
                  data={chartdata}
                  margin={{ top: 25, bottom: 25 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="timestamp" tick={{ fontSize: '.7rem' }} />
                  <YAxis />
                  <Tooltip />
                  <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                  <ReferenceLine y={0} stroke="#000" />
                  {BrushComponent}
                  <Line dataKey={chartname} fill="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
    </>
  )

}



renderHeaderCell = (name: string, alignLeft: boolean = true) => {
  return () => {
    return (
      <span className={alignLeft ? "align-left" : "align-right"}>{name}</span>
    );
  }
}

renderIndexCell = (row) => {
  return (
    <div style={{ paddingLeft: (row.metadata.depth * 15) + "px"}}>
      <button className={`toggle-button ${row.$state.isExpanded ? "expanded" : ""}`}
        onClick={row.toggleChildren}
        disabled={!row.metadata.hasChildren}>
        <span className={row.data.isWaldo ? "is-waldo" : ""}>{row.data.iotServiceID}</span>
      </button>
    </div>
  );
}

renderEditableCell = (row) => {
  return (
    <input type="text" value={row.data.contact}
      onChange={(event) => {
        // .updateData will notify the TreeTable instance to dispatch an onChange call with 
        // with a new value which includes the patched row data. The change will be visible 
        // if the new value is picked up and passed through TreeTable's value prop. 
        row.updateData({
          ...row.data,
          contact: event.target.value,
        });
      }}/>
  );
}

renderEmployeesCell = (row: Row<DemoDataItem>) => {
  return (
    <span className="employees-cell">{row.data.employees}</span>
  );
}

renderExpensesCell = (row: Row<DemoDataItem>) => {
  return (
    <span className="expenses-cell">{row.data.expenses}</span>
  );
}
