import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles'

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { DialogProvider } from "react-mui-dialog";

const GlobalAppCss = props => {
  const useStyles = makeStyles({
    '@global': {
      'a[data-test="sign-in-forgot-password-link"]': {
        display: 'none'
      },
      'a[data-test="sign-in-create-account-link"]': {
        display: 'none'
      },
      'div:has(> a[data-test="sign-in-forgot-password-link"])': {
        display: 'none'
      },
      'span:has(> a[data-test="sign-in-create-account-link"])': {
        display: 'none'
      },
    }
  })
  useStyles()
  return props.children
}

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <ThemeProvider theme={Themes.default}>
        <DialogProvider>
          <GlobalAppCss> 
            <CssBaseline />
            <App />
          </GlobalAppCss>
        </DialogProvider>
      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
