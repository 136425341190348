import React, { useEffect, useState, useRef, Component } from "react";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";
import ToggleButton from 'react-toggle-button'

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles"
import { useDialog } from "react-mui-dialog";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";


let renderHeaderCell;
let renderIndexCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;


export default function DeviceControlTuple2({ client, device, maps, controltype, controlkey, controlvalue, updated, inedits, onchange }) {
  var classes = useStyles();
  var theme = useTheme();
  const [value, setValue] = useState( controlvalue ? controlvalue : '');
  const [mydevice, setMydevice] = useState( device);
  const [myupdated, setMyupdated] = useState( updated);
  
  // periodically check current value in device and reflect the change accordingly
  let check = () => { setTimeout(() => { 
    console.log('check() value vs device[controlkey] vs mydevice[controlkey] vs controlvalue', device.iotServiceID, controlkey, value, device[controlkey], mydevice[controlkey], controlvalue); 
    //setValue(parseInt('' + mydevice[controlkey])); 
    //check();
  }, 1000); }
  //check();

  // check value in device[controlkey] every 10s and put it into local state
  /** 
  useEffect(() => {
    let interval = setInterval(() => {
      if ( device[controlkey] != value) setValue( device[controlkey]);
    }, 10000);
    return () => clearInterval(interval);
  }, [device[controlkey]]);
  */

  let drawall = (mydevice2, value2, updated2) => {
    let type = 'N'; // there are TF: true/false, TFN: true/false/send-as-number, and N: numeric input
    if ( controltype) type = controltype;
    console.log( 'DeviceControlTuple2.drawall() type,controlkey,controlvalue,value,value2', type, controlkey, controlvalue, value, value2);
    console.log( 'DeviceControlTuple2.drawall() updated,myupdated,updated2', updated, myupdated, updated2);
    if ( updated2 != updated) value2 = controlvalue;
    if ( updated2 != updated) setValue( controlvalue); // reset the value to that of the device
    if ( updated2 != updated) setMyupdated( updated); // reset the value to that of the device


    if ( type == 'COUT') {
      return (
        <>
        <ToggleButton color="primary" value={ value } 
          onToggle={(v) => { if ( mydevice2['iot_status'] == 'locked2') return; }}
          className={ mydevice2['iot_status'] == 'locked2' ? 'p-disabled' : ''}
        />
        </>
      )
    }
    if ( type == 'TF' || type == 'TFN') {
      value2 = parseInt( '' + value2);
      console.log( 'DeviceControlTuple2.drawall() type=TF value2', value2);
      return (
        <>
          <Button color="primary" 
            style={{ 'margin-right': '5px'}}
            onClick={(v) => { setValue( 1); check(); onchange( mydevice2.iotServiceID, controlkey, 1); }}
          >起動</Button>
          <Button color="primary"
            style={{ 'margin-left': '5px'}}
            onClick={(v) => { setValue( 0); check(); onchange( mydevice2.iotServiceID, controlkey, 0); }}
          >停止</Button>
          <span style={{"color": "red", "font-size":"smaller", display:'block', clear:'both'}}> 
            { 
              //( '' + value) == ( '' + controlvalue) ? ' ' : (' 変更あり（' + (value ? '停止中→運転' : '運転中→停止') + '）')
              ( value2 != mydevice2[controlkey] ? (' 変更後：' + (value2 ? '運転' : '停止')) : ' ')
            } 
          </span>
        </>
      )
    }
    if ( type == 'N') {
      //console.log('iot_status (N)', device['iot_status']);
      return (
        <>
          <TextField color="primary" label="" variant="standard" size="small"  
            value={ "" + value } 
            style={{ height: '1.1em', width: '5em', margin: '0px 5px 0px 0px', padding: '1px 1px' }}
            onChange={(e) => { setValue( e.target.value); check(); onchange( mydevice2.iotServiceID, controlkey, e.target.value); }}
            //disabled={ device['iot_status'] == 'locked2' ? true : false }
          />
          <span style={{"color": "red", "font-size":"smaller", display:'block', clear:'both'}}> 
            { 
              //(''+value) == (''+controlvalue) ? ' ' : (' 変更あり（' + controlvalue + '→' + value + '）')
              ( value2 != mydevice2[controlkey] ? (' 変更後：' + value2) : ' ')
            } 
          </span>
        </>
      )
    }
  }
  
  // redraw when any of mydevice, value, myupdated changes
  return (
    <>
    { drawall( mydevice, value, myupdated) }
    </>
  )

}

