import React, { useEffect, useState, useRef, Component } from "react";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Tooltip as ReactTooltip,
} from "@material-ui/core";
import {
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowRight as ArrowRight,
  KeyboardArrowUp as ArrowUp,
} from "@material-ui/icons";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";
import { useDialog } from "react-mui-dialog";
import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";


let client;
const LIST = gql(`
  query MyQuery($username: String!) {
    devices(username:$username) {
      items {
        iotServiceID
        updatedAt
        username
        deviceType
        deviceModel
        deviceName
        location
        tags
        wifi_rssi
        relay1
        relay2
        state
        output_rate
        frequency
        iot_topic_keymap
        iot_topic_keytype
        iot_topic_read
        iot_topic_write
        iot_status
        calcparams
        tags
        children {
          iotServiceID
          updatedAt
          username
          deviceType
          deviceModel
          deviceName
          location
          tags
          wifi_rssi
          relay1
          relay2
          state
          output_rate
          frequency
          iot_topic_keymap
          iot_topic_keytype
          iot_topic_read
          iot_topic_write
          iot_status
          calcparams
          tags
        }
      }
    }
  }
`)


let renderHeaderCell;
let renderIndexCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;


export default function DevicesFlat({ client, maps, onselect, onupdate, ondelete }) {
  var classes = useStyles();
  var theme = useTheme();
  const { openDialog } = useDialog();
  const [view, setView] = useState("table");
  const [device, setDevice] = useState({});
  const [devices, setDevices] = useState([]);
  const [version, setVersion] = useState();

  // sort
  const [orderby, setOrderby] = useState("");
  const [orderdir, setOrderdir] = useState("asc");

  const [parentchartdata, setParentChartData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  
  const [parentChartState, setParentChartState ] = useState("3時間分");
  const [chartState, setChartState] = useState("3時間分");

  const [ parentDeviceValue, setParentDeviceValue ] = useState("0");
  const [ deviceValue, setDeviceValue ] = useState("0");

  const [treeState, setTreeState] = useState(TreeState.create(devices)); // mockData
  const [treeStateData, setTreeStateData] = useState([]); // mockData
  const [key, setKey ] = useState(0);
  const [mainChartState, setMainChartState] = useState("monthly");
  const treeTableRef = useRef(null);
  
  //const client = useApolloClient();
  
  let reorder = (items2, key2, dir2) => {
    let key = key2 ? key2 : orderby;
    let dir = dir2 ? dir2 : orderdir;
    let tags = {}; // hash = unique tags from all devices
    // convert from { various keys, children: [{...}, {...}]} to  { data: { various keys}, children: [ { data: { various keys }}, ...]}
    let items = JSON.parse(JSON.stringify(items2));
    console.log('reorder() items:', items);
    let flatitems = [];
    let flatlist = {};
    // convert from { various keys, children: [{...}, {...}]} to  { data: { various keys}, children: [ { data: { various keys }}, ...]}
    items.forEach((item) => {
      let children = item.children;
      if ( ! children) children = [];
      delete item.children;
      flatitems.push(item);
      // do the same for children
      if ( ! flatlist[item.iotServiceID]) flatlist[item.iotServiceID] = item;
      children.forEach((child) => {
        var mychild = JSON.parse(JSON.stringify(child));
        if (flatlist[ mychild.iotServiceID]) mychild = flatlist[ mychild.iotServiceID];
        delete mychild.children;
        if ( ! mychild.parents) mychild.parents = [];
        mychild.parents.push(item.iotServiceID);
        flatlist[mychild.iotServiceID] = mychild;
        flatitems.push(mychild);
      });
    });
    // 2nd run -- attech children to parents
    Object.keys(flatlist).map((id) => {
      let mychild = flatlist[id];
      if ( mychild.tags) mychild.tags.split(' ').map((tag) => { tags[tag] = 1; });
      if ( ! mychild.parents) return;
      mychild.parents.map((pid) => {
        if ( ! flatlist[ pid]) return;
        if ( ! flatlist[pid].children) flatlist[pid].children = [];
        flatlist[pid].children.push(mychild);
      });
    });
    flatlist = Object.values(flatlist); // these are unique objects with multiple parents (if any)
    let data = []; 
    flatlist.map((item) => { 
      item = JSON.parse(JSON.stringify(item));
      let children = item.children;
      delete item.children;
      data.push({ data: item, children: item.children }); 
    });
    console.log('reorder() data:', data);
    if ( ! key) key = 'iotServiceID';
    // order the items in accordance with orderby value (key)
    let h = {}; let spacer = ' ';
    data.forEach((item) => { 
      let k = item.data[ key] ? item.data[ key] + ' ' : '';
      if ( k) k += item.data.iotServiceID; 
      else { k += spacer; spacer += ' '; } // for empty value, just make sure that they are distinct
      h[ k.toLowerCase()] = item;
    });
    console.log( 'reorder() keys, sorted keys:', JSON.stringify(Object.keys(h)), JSON.stringify(Object.keys(h).sort()));
    let keys = Object.keys(h).sort();
    let data2 = [];
    keys.forEach((key) => { 
      if ( dir == 'asc') data2.push(h[key]);
      else data2.unshift(h[key]);
    });
    // set the new tree state
    setTreeStateData(data2);
    setTreeState(TreeState.create(data2));
    onupdate( flatitems, Object.keys( tags));
  }


  // DEVICES
  let getdevices = () => { client.query({ query: LIST, variables: { username: localStorage.getItem('username')}, fetchPolicy: 'network-only'}).then((result) => { 
    console.log("DEVICES", result.data.devices.items);
    setVersion(localStorage.getItem('version')); 
    for ( let k in result.data.devices.items) result.data.devices.items[k].timestamp = new Date().getTime();
    
    // if any, parse calcparams as  k1=v1 k2=v2 ...
    for ( let k in result.data.devices.items) if ( result.data.devices.items[k].calcparams) {
      let calcparams = result.data.devices.items[k].calcparams;
      // if calcparams is string (not parsed yet), then parse it
      let calcparams2 = {};
      if ( typeof calcparams == 'string') calcparams.split(' ').map((item) => { let kv = item.split('='); calcparams2[kv[0]] = kv[1]; });
      else calcparams2 = calcparams;
      result.data.devices.items[k].calcparams = calcparams2;
    }

    setDevices( result.data.devices.items); 
    reorder( result.data.devices.items);
  });}
  useEffect(() => { getdevices(); }, []);


  let deleteDevice = (row) => { openDialog({
    title: maps.labels.alerts.delete_title,
    contentText: maps.labels.alerts.delete_warning,
    cancelButton: {
      children: maps.labels.alerts.cancel,
      props: {
        variant: "contained",
        color: "secondary",
      },
    },
    submitButton: {
      children: maps.labels.alerts.confirm,
      props: {
        variant: "contained",
        color: "primary",
      },
    },
    onSubmit: async () => { ondelete(row.data); }

  })}

  renderHeaderCell = (key: string, name: string, alignLeft: boolean = true) => {
    return () => {
      let items = [];
      if ( ! key || ! name) return items;
      items.push(<span className={alignLeft ? "align-left" : "align-right"}>{name}</span>);
      if ( key != orderby) items.push(<ArrowRight 
        onClick={() => { reorder(devices, key, 'asc'); setOrderby(key); setOrderdir('asc'); }}
      />);
      else if ( key == orderby && orderdir == 'desc') items.push(<ArrowDown 
        onClick={() => { reorder(devices, key, 'asc'); setOrderdir('asc'); }}
      />);
      else if ( key == orderby && orderdir == 'asc') items.push(<ArrowUp 
        onClick={() => { reorder(devices, key, 'desc'); setOrderdir('desc'); }}
      />);
      return items;
    }
  }

  let drawall = (v) => {
    // online processing of deleted items
    //let state = [];
    //treeStateData.map((item) => { state.push(item); });
    //setTreeState(TreeState.create(state));
    console.log('drawall() treeStateData', treeStateData);
    console.log('drawall() version vs localStorage.version', version, localStorage.getItem('version'));
    if ( '' + version != '' + localStorage.getItem('version')) {
      console.log('drawall() NEW VERSION!');
      setVersion( localStorage.getItem('version'));
      getdevices();
    }
    
    // infer keymap2 from maps.config.tableMap
    let keymap2 = [];
    if ( maps && maps.config && maps.config.tableMap) maps.config.tableMap.map((item) => { keymap2.push({ key: item.key, label: item.value}); });

    return (
      <>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TreeTable className="demo-tree-table"
              height={360}
              headerHeight={32}
              ref={treeTableRef}
              value={treeState}
              onChange={(newVal) => { console.log("`onChange` call", newVal); setTreeState(newVal); }}>
              <TreeTable.Column renderCell={renderIndexCell} renderHeaderCell={renderHeaderCell( 'iotServiceID', maps.labels.iotserviceid)} basis="180px" grow={0}/>
              <TreeTable.Column renderCell={(row) => { return (
                <span className="employees-cell" style={{ 'font-weight': 'bold'}}>
                  { row.data.tags ? row.data.tags : '' }
                </span>
              )}} renderHeaderCell={renderHeaderCell('tags', maps.labels.tags)}/>
              {(() => {
                // walk through the array [{ key, label}, ...]
                return keymap2.map((h, i) => {
                  if ( ! i) return; // skip the iotservice id
                  return (
                    <TreeTable.Column 
                      renderCell={(row) => { 
                        let stuff = ( h.key == 'updatedAt') ? new Date(1000*parseInt( '' + row.data.updatedAt)).toLocaleString('ja-JP') : row.data[h.key];
                        if ( h.key != 'location') return (
                          <span className="employees-cell">
                            { stuff }
                          </span>
                        )
                        return (
                          <ReactTooltip 
                            //placement="left"
                            //style={{ "z-index":100000 }}
                            title={stuff}
                            aria-label="add"
                            placement="right"
                            PopperProps={{
                            disablePortal: true,
                            popperOptions: {
                              positionFixed: true,
                              modifiers: {
                                preventOverflow: {
                                enabled: true,
                                boundariesElement: "window" // where "window" is the boundary
                                }
                              }
                            }
                            }}
                          >
                            <span 
                            className="unfoldable-cell"
                            //style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                            >
                            { stuff }
                            </span>
                        </ReactTooltip>
                        )
                      }}
                      renderHeaderCell={renderHeaderCell( h.key, h.label, false)} 
                    />
                  )
                });
              })()}
              <TreeTable.Column 
                renderCell={(row) => { return renderViewButton(row) }} 
                renderHeaderCell={ renderHeaderCell("", "")}
              />
            </TreeTable>
          </Grid>
        </Grid>
    </>
    );
  }

  renderViewButton = (row) => {
    return (
      <>
        <Link 
          class="link-button"
          style={{ 'margin-right': '5px'}}
          //to={{ pathname: "/app/dashboard", state:row }} 
          onClick={() => { 
            //console.log( 'selected DEVICE', row.data); 
            //console.log( 'selected PARENT DEVICE', row.data.parent);
            //setDevice(row.data); setView("details"); 
            onselect(row.data);
          }}
        >
          { maps.labels.edit }
        </Link>
        <Link 
          class="link-button"
          style={{ 'margin-left': '5px'}}
          //to={{ pathname: "/app/dashboard", state:row }} 
          onClick={() => { deleteDevice(row);  }}
        >
          { maps.labels.delete }
        </Link>
      </>
    );
  }
  
  // will loop through keys/values creating columns in the below table
  return drawall();

}




renderIndexCell = (row) => {
  return (
    <div style={{ paddingLeft: (row.metadata.depth * 15) + "px"}}>
      <button className={`toggle-button ${row.$state.isExpanded ? "expanded" : ""}`}
        onClick={row.toggleChildren}
        disabled={!row.metadata.hasChildren}>
        <span className={row.data.isWaldo ? "is-waldo" : ""}>{row.data.iotServiceID}</span>
      </button>
    </div>
  );
}

renderEditableCell = (row) => {
  return (
    <input type="text" value={row.data.contact}
      onChange={(event) => {
        // .updateData will notify the TreeTable instance to dispatch an onChange call with 
        // with a new value which includes the patched row data. The change will be visible 
        // if the new value is picked up and passed through TreeTable's value prop. 
        row.updateData({
          ...row.data,
          contact: event.target.value,
        });
      }}/>
  );
}

renderEmployeesCell = (row: Row<DemoDataItem>) => {
  return (
    <span className="employees-cell">{row.data.employees}</span>
  );
}

renderExpensesCell = (row: Row<DemoDataItem>) => {
  return (
    <span className="expenses-cell">{row.data.expenses}</span>
  );
}
