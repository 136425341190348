
// Warning: This file is automatically generated. It will be overwritten.
// Stage: dev
const awsmobile = {
    "region": "ap-northeast-1",
    "aws_appsync_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:0f07f6f4-1312-4684-ac56-58aeb0e2ccb9",
    "aws_user_pools_id": "ap-northeast-1_hZyzkpPi3",
    "aws_user_pools_web_client_id": "1385nvqmuk1rk4dhqhlmsm2vam",
    "aws_appsync_graphqlEndpoint": "https://egm6rlc5d5fvjeufaz5fhdhoum.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
};
export default awsmobile;
