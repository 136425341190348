import React, { useEffect, useState, useRef, Component } from "react";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";
import ToggleButton from 'react-toggle-button'

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles"
import { useDialog } from "react-mui-dialog";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";

import DeviceDetailsChart from "./DeviceDetailsChart";


// parameters: iotServiceID, from, to
const DEVICESET = gql(`
  query MyQuery($iotServiceID: String, $key: String, $value: String) {
    deviceSet(iotServiceID: $iotServiceID, key: $key, value: $value) {
      iotServiceID
      status
    }
  }
`)


let renderHeaderCell;
let renderIndexCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;


export default function DeviceControlTuple({ client, device, maps, controlkey, controlname, controlvalue, onchange }) {
  var classes = useStyles();
  var theme = useTheme();
  const { openDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const [value, setValue ] = useState( parseInt( '' + controlvalue));
  console.log("DeviceControlTuple: controlkey/controlname/value", controlkey, controlname, device[controlkey]);

  //const client = useApolloClient();
  let controlkeys = [];
  let controltypes = [];
  let controlmap = {};
  let controlconfig = {};
  if ( device.iot_topic_keytype) device.iot_topic_keytype.split(',').forEach((v) => {
    let vs = v.split('='); if ( vs.length != 2) return;
    controlkeys.push(vs[0]);
    controltypes.push(vs[1]);
    controlmap[ vs[0]] = vs[1].split(':').shift();
    controlconfig[ vs[0]] = vs[1].split(':') //  example:  N:50:100
  })

  let confirm = (k,v) => { openDialog({
    title: maps.labels.alerts.control_title_after,
    contentText: maps.labels.alerts.control_message_after,
    cancelButton: false,
    submitButton: {
      children: maps.labels.alerts.ok,
      props: {
        variant: "contained",
        color: "primary",
      },
    },
    onSubmit: async () => { }
  })}
  let control = (k,v) => { openDialog({
    title: maps.labels.alerts.control_title_before,
    contentText: maps.labels.alerts.control_message_before.replaceAll('KEY', k).replaceAll('VALUE', v),
    cancelButton: {
      children: maps.labels.alerts.cancel,
      props: {
        variant: "contained",
        color: "secondary",
      },
    },
    submitButton: {
      children: maps.labels.alerts.confirm,
      props: {
        variant: "contained",
        color: "primary",
      },
    },
    onSubmit: async () => {
      let value = 0; 
      setLoading(true);
      if ( controlmap[ controlkey] == 'TF') value = v ? 1 : 0;
      if ( controlmap[ controlkey] == 'TFN') value = v ? 1 : 0;
      if ( controlmap[ controlkey] == 'N') {
        value = parseInt( '' + v); 
        if ( controlconfig[ controlkey].length > 2) {
          if ( value < parseInt( controlconfig[ controlkey][1])) value = parseInt( controlconfig[ controlkey][1]);
          if ( value > parseInt( controlconfig[ controlkey][2])) value = parseInt( controlconfig[ controlkey][2]);
        }
      }
      //return console.log( 'CONTROL k/v', k, v);
      setValue(v);
      client.query({ query: DEVICESET, variables: { iotServiceID: device['iotServiceID'], key: controlkey, value: controlmap[controlkey]+':'+value}, fetchPolicy: 'network-only' })
      .then((result) => { setLoading(false); confirm(); if ( onchange) onchange();  })
    }
  })}

  // check value in device[controlkey] every 10s and put it into local state
  useEffect(() => {
    let interval = setInterval(() => {
      if ( device[controlkey] != value) setValue( device[controlkey]);
    }, 10000);
    return () => clearInterval(interval);
  }, [device[controlkey]]);

  let type = 'N'; // there are TF: true/false, TFN: true/false/send-as-number, and N: numeric input
  if ( controlmap[controlkey]) type = controlmap[controlkey];

  console.log("DeviceControlType TYPESWITCH deviceid/controlkey/type/value", device.iotServiceID, controlkey, type, device[controlkey])
  if ( type == 'TF') {
    console.log('iot_status (TF)', device['iot_status']);
    return (
      <>
      <ToggleButton color="primary" value={ value } 
        onToggle={(v) => { if ( device['iot_status'] == 'locked2') return; control(controlkey, !v); }}
        className={ device['iot_status'] == 'locked' ? 'p-disabled' : ''}
      />
      {(() => { if ( loading) { return (
        <>
        <span> ロード中... </span>
        </>
      ) }})()}
      </>
    )
  }
  if ( type == 'TFN') {
    console.log('iot_status (TFN)', device['iot_status']);
    return (
      <>
      <ToggleButton color="primary" value={ value } 
        onToggle={(v) => { if ( device['iot_status'] == 'locked2') return; control( controlkey, !v); }} 
        className={ device['iot_status'] == 'locked' ? 'p-disabled' : ''}
      />
      {(() => { if ( loading) { return (
        <>
        <span> ロード中... </span>
        </>
      ) }})()}
      </>
    )
  }
  if ( type == 'N') {
    console.log('iot_status (N)', device['iot_status']);
    return (
      <>
        <TextField color="primary" label="" variant="outlined" value={ "" + value } 
          onChange={(e) => { setValue( e.target.value); console.log( 'new v', e.target.value); }}
          disabled={ device['iot_status'] == 'locked2' ? true : false }
        />
        <Button color="primary" 
          onClick={() => { control(controlkey, value); }}
          disabled={ device['iot_status'] == 'locked' ? true : false }
        >
          決定
        </Button>
        {(() => { if ( loading) { return (
          <>
          <span> ロード中... </span>
          </>
        ) }})()}
      </>
    )
  }

}

