import React, { useEffect, useState, useRef, Component } from "react";
import * as Icons from "@material-ui/icons";
import {Link} from 'react-router-dom';
import {
  Card, CardHeader, CardContent, CardActions,
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";
import ToggleButton from 'react-toggle-button'

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles"
import { useDialog } from "react-mui-dialog";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";

import DeviceDetailsChart from "./DeviceDetailsChart";
import DeviceControlTuple2 from "./DeviceControlTuple2";


const DEVICE = gql(`
  query MyQuery($filter: String) {
    devices(filter:$filter) {
      items {
        iotServiceID
        updatedAt
        username
        deviceType
        deviceModel
        deviceName
        location
        wifi_rssi
        relay1
        relay2
        state
        output_rate
        frequency
        voltage
        current
        power
        Temperature
        Humidity
        PM10
        PM25
        PM40
        PM100
        analogIn1
        temp_offset
        humi_offset
        temp_alert
        pm_alert
        iot_topic_keymap
        iot_topic_keytype
        iot_topic_read
        iot_topic_write
        iot_status
        calcparams
        tags
      }
    }
  }
`)

// parameters: iotServiceID, from, to
const DEVICESET = gql(`
query MyQuery($iotServiceID: String, $what: String) {
  deviceSet(iotServiceID: $iotServiceID, what: $what) {
    iotServiceID
    status
  }
}
`)


let renderHeaderCell;
let renderIndexCell;
let renderEditableCell;
let renderEmployeesCell;
let renderExpensesCell;
let renderViewButton;
let tuples = {}
let labels = {}
let labels2 = {}
let inedits = {}
let timestamps = {}

export default function DeviceControl({ client, device, maps, onchange }) {
  var classes = useStyles();
  var theme = useTheme();
  const { openDialog } = useDialog();
  const [loading, setLoading] = useState(false);
  const [inedit, setInedit] = useState(false);
  const [mydevice, setMyDevice] = useState(device);
  const [mytype, setMyType ] = useState('');
  const [updated, setUpdated] = useState((new Date()).getTime());


  let rtypemap = {};
  if ( maps.config && maps.config.deviceModelLabels) for ( var k in maps.config.deviceModelLabels) rtypemap[maps.config.deviceModelLabels[k]] = k;
  let type = ( rtypemap && device && rtypemap[device.deviceType]) ? rtypemap[device.deviceType] : '';
  let model = device.deviceModel; // deviceModel is now part of the key
  console.log('MYTYPE type/deviceModel,rtypemap', type, device.deviceType, rtypemap);
  if ( ! type) return (<div>...</div>);
  if ( ! mytype) setMyType(type);
  
  //const client = useApolloClient();
  let controltuples = []; // [ { key, value}, ...]
  let controlmap = {}; // { key: TF, ...}
  let controlconfig = {}; // { key: [type, min, max], ...}
  let K = 'controlMap2-'+mytype + '-' + model;
  if ( maps && maps.config && maps.config[K]) controltuples = maps.config[K];
  K = 'controlWriteType-'+mytype + '-' + model;
  if ( maps && maps.config && maps.config[K]) controlmap = maps.config[K];
  for ( var k in controlmap) controlconfig[k] = controlmap[k].split(":");
  for ( var k in controlmap) controlmap[k] = controlconfig[k][0];
  console.log('DeviceControl: controltuples', controltuples);
  console.log('DeviceControl: controlmap', controlmap);
  console.log('DeviceControl: controlconfig', controlconfig);

  let myid = mydevice.iotServiceID;
  if ( !tuples[myid]) tuples[myid] = {};
  if ( !labels[myid]) labels[myid] = {};
  if ( !labels2[myid]) labels2[myid] = {};
  if ( !inedits[myid]) inedits[myid] = {};
  if ( !timestamps[myid]) timestamps[myid] = new Date().getTime();


  let draw_cardheader;
  let draw_controls;
  let makestate = (force) => { 
    controltuples.map((info) => {
        let key = info.key;
        let label = info.value;
        let label2 = info.value;
        let type = controlmap[key];
        let value = device[key] ? String('' + mydevice[key]) : '';
        //if ( value == 'null') return; // this key is has not been set for this device
        if ( labels[myid][ key]) return; // this key has already been set
        tuples[myid][ key] = value;
        labels[myid][ key] = label;
        labels2[myid][ key] = label2;
        inedits[myid][ key] = false;
        timestamps[myid] = new Date().getTime();
    })
    if ( force) setUpdated((new Date()).getTime());
    console.log( "DeviceControl.makestate() : tuples,inedits", tuples, inedits); 
  }


  let reload_device = () => { setLoading( true); client.query({ query: DEVICE, variables: { filter: device.iotServiceID}, fetchPolicy: 'network-only'}).then((result) => { 
    setLoading( false);
    console.log("DEVICE (reload)", result.data.devices.items);
    let device2 = result.data.devices.items[ 0];
    device2.timestamp = new Date().getTime();
    device2.calcparams = mydevice.calcparams; // do not allow change in calcparams on reload
    setMyDevice(device2);
    makestate(true);
    setInedit(false);
  });}


  /*
  let editstate = false; 
  for ( let k in inedits[myid]) if ( inedits[myid][k]) editstate = true;
  if ( editstate != inedit) setInedit(editstate);
  console.log( 'DeviceControl: editstate', editstate);
  */

  let confirm = () => { openDialog({
    title: maps.labels.alerts.control_title_after,
    contentText: maps.labels.alerts.control_message_after,
    cancelButton: false,
    submitButton: {
      children: maps.labels.alerts.ok,
      props: {
        variant: "contained",
        color: "primary",
      },
    },
    onSubmit: async () => { }
  })}
  let control = () => { openDialog({
    title: maps.labels.alerts.control_title_before,
    contentText: maps.labels.alerts.control_message_before,
    cancelButton: {
      children: maps.labels.alerts.cancel,
      props: {
        variant: "contained",
        color: "secondary",
      },
    },
    submitButton: {
      children: maps.labels.alerts.confirm,
      props: {
        variant: "contained",
        color: "primary",
      },
    },
    onSubmit: async () => {
      let myid = mydevice.iotServiceID;
      setLoading(true);
      
      // build "what" string and send it via graphGL
      let tuples2 = {};
      for ( let k in tuples[myid]) {
        let v = tuples[myid][k];
        if ( controlmap[ k] == 'TF') v = ( parseInt( '' + v) ? 1 : 0);
        if ( controlmap[ k] == 'TFN') v = ( parseInt( '' + v) ? 1 : 0);
        if ( controlmap[ k] == 'N') {
          v = parseFloat( '' + v); 
          if ( controlconfig[ k].length > 2) {
            if ( v < parseFloat( controlconfig[ k][1])) v = parseFloat( controlconfig[ k][1]);
            if ( v > parseFloat( controlconfig[ k][2])) v = parseFloat( controlconfig[ k][2]);
          }
        }
        tuples2[ k] = controlmap[ k] + ':' + v;
      }
      
      let what = '';
      for ( let k in tuples2) {
        if ( what.length > 0) what += ' '; // concat with space
        what += k + '=' + tuples2[k];
      }
      console.log('what', what);
      
      client.query({ query: DEVICESET, variables: { iotServiceID: mydevice['iotServiceID'], what: what}, fetchPolicy: 'network-only' })
      .then((result) => { setLoading(false); for ( let k2 in inedits[myid]) inedits[ myid][k2] = false; confirm();  })
    }
  })}
  

  draw_cardheader = (updated2) => {
    return (
      <CardHeader 
        title={<>
        <span style={{ 'font-size': 'smaller', 'font-weight': 'bold'}}> 
          制御
        </span>
        <Icons.Refresh 
          style={{ "margin-left": "10px", "cursor": "pointer" }}
          onClick={(e) => { reload_device(); }}
        />
        <span style={{ color:'red', 'font-size': 'smaller'}}> 
          { loading ? ' ロード中...' : ' '} 
        </span>
        <span style={{ 'font-size': 'smaller'}}> 
          自動更新はされません。 
        </span>
        </>}
        //</Card>style={{ 'background-color': '#000', opacity: '0.2' }} 
        action={(
          <>
          <Button color="secondary" 
            disabled={ ! inedit }
            onClick={() => { let mydevice2 = onchange(); timestamps[myid] = mydevice2.timestamp; console.log('tuples', tuples); control(); }}
          >
            送信
          </Button>
          </>
        )}
        />
    );
  }

  draw_controls = (mydevice2, updated2) => {
    let items = []; let myid = mydevice.iotServiceID;
          //let mydevice2 = onchange();
          console.log('timestamps localvar,mydevice,mydevice2', timestamps[myid], mydevice.timestamp, mydevice2.timestamp);
          if ( mydevice.timestamp != timestamps[myid]) timestamps[myid] = mydevice.timestamp;
          //if ( mydevice2.timestamp != timestamps[myid]) for ( let k2 in tuples[myid]) inedits[myid][k2] = false;
          //timestamps[myid] = mydevice.timestamp;
          for ( let k in tuples[myid]) {
            items.push(
            <Grid item xs={4} style={{ float:"left", margin: "10px 10px", width: "45%", position: "relative"}}>
              <Typography color="text" colorBrightness="secondary" noWrap>{ labels2[myid][k] }</Typography>
              <Typography size="md">
                <DeviceControlTuple2
                  client={client} 
                  device={mydevice2}
                  maps={maps} 
                  controltype={controlmap[k]}
                  controlkey={k}
                  controlvalue={mydevice2[k]}
                  updated={updated2}
                  inedits={ inedits[myid]}
                  onchange={(id2,k2,v2) => { 
                    inedits[id2][k2] = v2 == mydevice2[ k2] ? false : true; 
                    tuples[id2][k2] = v2; 
                    let count = 0; for ( let k3 in inedits[id2]) if ( inedits[id2][k3]) count++;
                    if ( count == 0) setInedit(false); else setInedit(true);
                    console.log('tuples(update) (id='+id2+',k='+k2+',v='+v2+')', tuples); 
                  }}
                />
              </Typography>
            </Grid>
            );
          }
          //if ( mydevice2) setMyDevice(mydevice2);
          console.log('tuples(card update): tuples/mydevice', tuples, mydevice2);
        return items;
  }

  makestate();

  // card should take up 100% width
  return (
      <>
      <Grid
        container
        direction="row"
        justify="space-between" // "space-between"
        alignItems="left" // left
        margin={{ top: 50, bottom: 50, left: 5, right: 5 }}
        rowSpacing={50}
      >
      <Card className={classes.card} style={{width: '100%'}}>
        { draw_cardheader(updated) }
        <CardContent container style={{width:'100%'}}>
        { draw_controls(mydevice, updated) }
        </CardContent>
      </Card>
    </Grid>
    </>
  );
        
}
      
      