import React, { useEffect, useState, useRef, Component } from "react";
import {Link} from 'react-router-dom';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";

import {
  ResponsiveContainer,
  Legend,
  ReferenceLine,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
} from 'recharts';
import Chart from "react-apexcharts";

import MUIDataTable from "mui-datatables";

// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import { Typography, Button } from "../../components/Wrappers";
import Dot from "../../components/Sidebar/components/Dot";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

import { useApolloClient, ApolloClient, InMemoryCache, gql } from '@apollo/client';

import Devices from "./Devices";
import DeviceDetails from "./DeviceDetails";

// import { TreeTable, TreeState } from 'cp-react-tree-table';
// import TreeDataTable from 'cp-react-tree-table';
import { TreeTable, TreeState, TreeNode, Row } from "cp-react-tree-table";
//import "./styles/index.css";
//import "./styles/header.css";
import "./styles/table.css";
import "./styles/responsive.css";

import { labels } from "../../components/App"


// keys
let keys = "iotServiceID deviceType deviceModel location deviceName".split(" ");
let names = "IoTサービスID,デバイス分類,デバイス型番,設置場所,デバイス名前".split(",");
let keymap = {}; keys.map((k, i) => keymap[k] = names[i]);
let keymap2 = []; keys.map((k, i) => keymap2.push({ key: k, label: names[i]}));

let keys2 = "updatedAt deviceType deviceModel wifi_rssi".split(" ");
let names2 = "最新通信日,デバイス分類,デバイス型番,Wi-Fi電波強度".split(",");
let key2map = {}; keys2.map((k, i) => key2map[k] = names2[i]);
let key2map2 = []; keys2.map((k, i) => key2map2.push({ key: k, label: names2[i]}));

// control keys
let controlkeys = "relay1,relay2,state,frequency".split(",");
let controlnames = "リレー１,リレー2,運転状況,出力速度".split(",");
let controlnames2 = "リレー１,リレー2,運転状況,出力速度".split(",");
let controltypes = 'TF,TF,TF,N'.split(",");
let controlkeymap = {}; controlkeys.map((k, i) => controlkeymap[k] = { key: k, label: controlnames[i], label2: controlnames2[i], type: controltypes[i]});
let controlkeymap2 = []; controlkeys.map((k, i) => controlkeymap2.push({ key: k, label: controlnames[i], label2: controlnames2[i], type: controltypes[i]}));

// control keys
let showcontrolkeys = "relay1,relay2,state,frequency".split(",");
let showcontrolnames = "リレー１,リレー2,運転状況,出力速度".split(",");
let showcontrolnames2 = "リレー１,リレー2,運転状況,出力割合".split(",");
let showcontroltypes = 'TF,TF,TF,N'.split(",");
let showcontrolkeymap = {}; showcontrolkeys.map((k, i) => showcontrolkeymap[k] = { key: k, label: showcontrolnames[i], label2: showcontrolnames2[i], type: showcontroltypes[i]});
let showcontrolkeymap2 = []; showcontrolkeys.map((k, i) => showcontrolkeymap2.push({ key: k, label: showcontrolnames[i], label2: showcontrolnames2[i], type: showcontroltypes[i]}));

// chart keys
let chartkeys = "co2 temperature humidity state frequency position conversion_rate relay1 relay2".split(" ");
let chartnames = "CO2 温度 湿度 運転状況 出力速度 アナログ出力値 変換値 リレー1運転状況 リレー2運転状況".split(" ");  
let chartkeymap = {}; chartkeys.map((k, i) => chartkeymap[k] = chartnames[i]);
//let maps = { labels, keymap, keymap2, key2map, key2map2, controlkeymap, controlkeymap2, chartkeymap, showcontrolkeymap, showcontrolkeymap2 };
//console.log( 'maps', maps);


const CONFIG = gql(`
  query MyQuery($username: String) {
    getConfig(username: $username) {
        config
    }
  }
`);


export default function Tables() {
  var classes = useStyles();
  var theme = useTheme();
  const [view, setView] = useState("table");
  const [device, setDevice] = useState({});
  const [devices, setDevices] = useState([]);

  const [parentchartdata, setParentChartData] = useState([]);
  const [chartdata, setChartData] = useState([]);
  
  const [parentChartState, setParentChartState ] = useState("3時間分");
  const [chartState, setChartState] = useState("3時間分");

  const [parentDeviceValue, setParentDeviceValue ] = useState("0");
  const [deviceValue, setDeviceValue ] = useState("0");

  const [treeState, setTreeState] = useState(TreeState.create(devices)); // mockData
  const [key, setKey ] = useState(0);
  const [mainChartState, setMainChartState] = useState("monthly");
  const treeTableRef = useRef(null);
  
  // config, all/general, then specific for map4type mapping
  const [config, setConfig] = useState({}); // { }
  const [maps, setMaps] = useState({labels:labels, config: {}}); // { labels, keymap, keymap2, key2map, key2map2, controlkeymap, controlkeymap2, chartkeymap, showcontrolkeymap, showcontrolkeymap2 }
  const [map4types, setMap4Types] = useState({tableMap:{}}); // { deviceType: [ deviceModel, ... ] }
  

  const client = useApolloClient();
  
  // CONFIG
  useEffect(() => { client.query({ query: CONFIG, variables: { username: localStorage.getItem('username') } }).then((result) => {
    console.log( 'type-model map', result);
    if ( ! result || ! result.data || ! result.data.getConfig) return;
    let config = JSON.parse(result.data.getConfig.config);
    console.log('config', config);
    
    // set the entire config object
    setConfig(config);
    let mymaps = { labels, config}
    console.log("useEffect mymaps()", mymaps);
    setMaps(mymaps);
  })}, []);

  
  if ( view === "details") { 
    console.log('DETAILS switch, device,maps', device, maps);  
    let items = [];
    device.childs.map((child) => {
        console.log('drawing child', child);
        items.push(
          <>
           <div style={{ height: 10 }} />
           <Link 
            style={{ color: theme.palette.text.hint + "80", textDecoration: "none", fontSize: 18, cursor: "pointer"  }}
            onClick={() => { setView("table"); localStorage.setItem('view', 'table'); }}
          > 
              { labels.backtotable }
          </Link>
          <div style={{ height: 20 }} />
          <PageTitle title={"" + child.location + " " + child.deviceName +  " (" + child.iotServiceID +")" + (child.tags ? ' #(' + child.tags + ')' : '') } />
          <DeviceDetails client={client} device={child} maps={maps} />
          <div style={{ height: 50 }} />
          </>
        );
    });
    return items;
  }
  if ( view === "table") return (
    <>
      <PageTitle title="デバイス一覧" />
      <Devices 
        client={client} 
        onselect={(d) => { 
          setDevice(d); 
          setView('details'); 
          localStorage.setItem('view', 'details');
          localStorage.setItem('detailstag', d.tag);
        }} 
        maps={maps} 
      />
    </>
  )
  
}
